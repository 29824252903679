import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import { caseStudiesData } from "@constants";
// import LanguageForm from "@containers/language-translation/form";
const LanguageForm = React.lazy(() => import("@containers/language-translation/form"));
// import FeatureGrid from "@components/feature-grid";
const FeatureGrid = React.lazy(() => import("@components/feature-grid"));
// import PartnerArea from "@containers/partner/layout-01";
const PartnerArea = React.lazy(() => import("@containers/partner/layout-01"));
// import FaqArea from "@containers/faq/layout-03";
const FaqArea = React.lazy(() => import("@containers/faq/layout-03"));
// import LanguageTranslationIntro from "@containers/language-translation/intro";
const LanguageTranslationIntro = React.lazy(() => import("@containers/language-translation/intro"));
// import LanguageKeywords from "@containers/language-translation/keywords";
const LanguageKeywords = React.lazy(() => import("@containers/language-translation/keywords"));
// import LanguageExpertise from "@containers/language-translation/expertise";
const LanguageExpertise = React.lazy(() => import("@containers/language-translation/expertise"));
// import LanguageTranslationTips from "@containers/language-translation/translation-tips";
const LanguageTranslationTips = React.lazy(() => import("@containers/language-translation/translation-tips"));
// import LanguageVarieties from "@containers/language-translation/varieties";
const LanguageVarieties = React.lazy(() => import("@containers/language-translation/varieties"));
// import LanguageTranslators from "@containers/language-translation/translators";
const LanguageTranslators = React.lazy(() => import("@containers/language-translation/translators"));
// import LanguageVoiceovers from "@containers/language-translation/voiceovers";
const LanguageVoiceovers = React.lazy(() => import("@containers/language-translation/voiceovers"));
// import LanguageSoftware from "@containers/language-translation/software";
const LanguageSoftware = React.lazy(() => import("@containers/language-translation/software"));
// import LanguageGames from "@containers/language-translation/games";
const LanguageGames = React.lazy(() => import("@containers/language-translation/games"));
// import LanguageServices from "@containers/language-translation/services";
const LanguageServices = React.lazy(() => import("@containers/language-translation/services"));
// import LanguageDocuments from "@containers/language-translation/documents";
const LanguageDocuments = React.lazy(() => import("@containers/language-translation/documents"));
const LanguageFacts = React.lazy(() => import("@containers/language-translation/facts"));
const HowWeWork = React.lazy(() => import("@containers/global/how-we-work"));
const CaseStudy = React.lazy(() => import("@containers/global/case-study/layout-01"));


const BrazilianPortTranslationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  const isSSR = typeof window === "undefined"
  return (
    <Layout location={location}>
      <Seo
        title="Portuguese (Brazilian) Translation Services | Andovar"
        description="Professional Portuguese (Brazilian) Translation Services. Our Portuguese Translation Company has over 500 qualified Translators and Editors. Get a Free Quote!"
         />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["brazilian-portuguese-translation-header"]} />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <LanguageForm />
            <FeatureGrid
              data={content["brazilian-portuguese-translation-features"]}
            />
            <PartnerArea data={content["brazilian-portuguese-translation-logo"]} />
            <LanguageTranslationIntro
              data={content["brazilian-portuguese-translation-intro"]}
            />
            <LanguageKeywords
              data={content["brazilian-portuguese-translation-keyword"]}
            />
            <LanguageTranslationIntro
              data={content["brazilian-portuguese-translation-services"]}
            />
            <LanguageExpertise
              data={content["brazilian-portuguese-translation-expertise"]}
            />
            <LanguageTranslationTips
              data={content["brazilian-portuguese-translation-tip"]}
            />
            <LanguageVarieties
              data={content["brazilian-portuguese-translation-varities"]}
            />
            <LanguageTranslators
              data={content["brazilian-portuguese-translation-translator"]}
            />
            <LanguageVoiceovers
              dataVoiceOver={content["brazilian-portuguese-translation-voiceover"]}
              dataWebsite={content["brazilian-portuguese-translation-website"]}
            />
            <LanguageSoftware
              data={content["brazilian-portuguese-translation-software"]}
            />
            <LanguageGames
              data={content["brazilian-portuguese-translation-games"]}
            />
            <LanguageServices
              intoEnglish={content["brazilian-portuguese-into-english"]}
              subtitlesData={content["brazilian-portuguese-translation-subtitle"]}
            />
            <LanguageDocuments
              documentData={content["brazilian-portuguese-translation-document"]}
              elearningData={content["brazilian-portuguese-translation-elearing"]}
            />
            <LanguageFacts
              data={content["brazilian-portuguese-translation-facts"]}
            />
            <HowWeWork data={content["howwework"]} />
            <FaqArea data={content["brazilian-portuguese-translation-faq"]} />
            <CaseStudy data={caseStudiesData} />
          </React.Suspense>
        )}
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query brazilianTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "brazilian-portuguese-translation" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

BrazilianPortTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default BrazilianPortTranslationPage;
